import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import chl204SafetyTable from "../../../assets/LYM-safety-204-small-V2.svg"
import chl087SafetyTable from "../../../assets/LYM-safety-087-small-V3.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions in Patients With Relapsed or Refractory Classical Hodgkin Lymphoma (R/R cHL)`,
    keywords: `relapsed or refractory classical hodgkin lymphoma adverse reactions`,
    description: `Learn about selected immune-mediated adverse reactions and the most common adverse reactions to KEYTRUDA® (pembrolizumab) associated with the KEYNOTE-204 and KEYNOTE-087 clinical trials.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/classical-hodgkin-lymphoma/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of adult patients with relapsed or refractory classical Hodgkin lymphoma (cHL). KEYTRUDA is indicated for the treatment of pediatric patients with refractory classical Hodgkin lymphoma (cHL), or cHL that has relapsed after 2 or more lines of therapy."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/classical-hodgkin-lymphoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const chlSafety1Alt = "Adverse Reactions Occurring in ≥10% of Patients Receiving KEYTRUDA® (pembrolizumab) in KEYNOTE-204"
const chlSafety2Alt = "Adverse Reactions Occurring in ≥10% of Patients Receiving KEYTRUDA® (pembrolizumab) in KEYNOTE-087"

//from /src/util/data/indications.js
const indicationId = 20;
const jobCode = jobCodes[20].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Incidence of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Management of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;204' }, 
    { label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;087' }
]

const KEYNOTE204dataTableFootnotes = [
    {
        label: "c.",
        text: "Graded per NCI CTCAE v4.0."
    },
    {
        label: "d.",
        text: "Adverse reactions in BV arm were Grade 3 only."
    },
    {
        label: "e.",
        text: "Includes acute sinusitis, nasopharyngitis, pharyngitis, pharyngotonsillitis, rhinitis, sinusitis, sinusitis bacterial, tonsillitis, upper respiratory tract infection, viral upper respiratory tract infection."
    },
    {
        label: "f.",
        text: "Includes arthralgia, back pain, bone pain, musculoskeletal discomfort, musculoskeletal chest pain, musculoskeletal pain, myalgia, neck pain, noncardiac chest pain, pain in extremity."
    },
    {
        label: "g.",
        text: "Includes diarrhea, gastroenteritis, colitis, enterocolitis."
    },
    {
        label: "h.",
        text: "Includes abdominal discomfort, abdominal pain, abdominal pain lower, abdominal pain upper."
    },
    {
        label: "i.",
        text: "Includes fatigue, asthenia."
    },
    {
        label: "j.",
        text: "Includes dermatitis acneiform, dermatitis atopic, dermatitis allergic, dermatitis contact, dermatitis exfoliative, dermatitis psoriasiform, eczema, rash, rash erythematous, rash follicular‚ rash maculopapular, rash papular, rash pruritic, toxic skin eruption. "
    },
    {
        label: "k.",
        text: "Includes cough, productive cough."
    },
    {
        label: "l.",
        text: "Includes pneumonitis, interstitial lung disease."
    },
    {
        label: "m.",
        text: "Includes dyspnea, dyspnea exertional, wheezing."
    },
    {
        label: "n.",
        text: "Includes dysaesthesia, hypoaesthesia, neuropathy peripheral, paraesthesia, peripheral motor neuropathy, peripheral sensorimotor neuropathy, peripheral sensory neuropathy, polyneuropathy."
    },
    {
        label: "o.",
        text: "Includes headache, migraine, tension headache."
    },

];

const KEYNOTE087dataTableFootnotes = [
    {
        label: "p.",
        text: "Graded per NCI CTCAE v4.0."
    },
    {
        label: "q.",
        text: "Includes fatigue, asthenia."
    },
    {
        label: "r.",
        text: "Includes cough, productive cough."
    },
    {
        label: "s.",
        text: "Includes dyspnea, dyspnea exertional, wheezing."
    },
    {
        label: "t.",
        text: "Includes back pain, myalgia, bone pain, musculoskeletal pain, pain in extremity, musculoskeletal chest pain, musculoskeletal discomfort, neck pain."
    },
    {
        label: "u.",
        text: "Includes diarrhea, gastroenteritis, colitis, enterocolitis."
    },
    {
        label: "v.",
        text: "Includes rash, rash maculopapular, drug eruption, eczema, eczema asteatotic, dermatitis, dermatitis acneiform, dermatitis contact, rash erythematous, rash macular, rash papular, rash pruritic, seborrhoeic dermatitis, dermatitis psoriasiform."
    },
    {
        label: "w.",
        text: "Includes neuropathy peripheral, peripheral sensory neuropathy, hypoesthesia, paresthesia, dysesthesia, polyneuropathy."
    },
]

const KEYNOTE204dataTableDefinition = '<p>Q3W = every 3 weeks; NCI CTCAE = National Cancer Institute Common Terminology Criteria for Adverse Events; BV = Brentuximab Vedotin.<p>';

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                <p><strong>Serious adverse reactions occurred in 30% of patients who received KEYTRUDA.</strong></p>
                                <ul>
                                    <li>Serious adverse reactions in ≥1% included pneumonitis, pneumonia, pyrexia, myocarditis, acute kidney injury, febrile neutropenia, and sepsis.</li>
                                    <li>Three patients (2%) died from causes other than disease progression: 2 from complications after allogeneic HSCT and 1 from unknown cause.</li>
                                </ul>
                                <p>KEYTRUDA was discontinued due to adverse reactions in 14% of patients; 7% of patients discontinued treatment due to pneumonitis. Dosage interruption due to an adverse reaction occurred in 30% of patients.</p>
                            </TextBlock>
                            <DataTable
                                title='Adverse Reactions in ≥10% of Patients in KEYNOTE&#8288;-&#8288;204'
                                footnotes={KEYNOTE204dataTableFootnotes}
                                definitions={KEYNOTE204dataTableDefinition}
                                mobileImage={() => <img src={chl204SafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={chlSafety1Alt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/LYM-safety-204-large-V2.png" alt={chlSafety1Alt}/>}
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[3].label}>
                            <TextBlock>
                                <p>In 210 patients, serious adverse reactions occurred in 16% of patients; serious reactions that occurred in ≥1% of patients included pneumonia, pneumonitis, pyrexia, dyspnea, GVHD, and herpes zoster.</p>
                                <ul>
                                    <li>Two patients died from causes other than disease progression; 1 from GVHD after subsequent allogeneic HSCT and 1 from septic shock.</li>
                                </ul>
                                <p>KEYTRUDA was discontinued due to adverse reactions in 5% of patients, and dosage was interrupted due to adverse reactions in 26% of patients.</p>
                            </TextBlock>
                            <DataTable
                                title='Adverse Reactions in ≥10% of Patients in KEYNOTE&#8288;-&#8288;087'
                                footnotes={KEYNOTE087dataTableFootnotes}
                                mobileImage={() => <img src={chl087SafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={chlSafety2Alt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/LYM-safety-087-large-V3.png" alt={chlSafety2Alt}/>}
                            />
                        </PageSection>

                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
